import * as React from "react";

import { EGDSHeading, EGDSSubheading, EGDSText } from "@egds/react-core/text";

import { WizardRegionHotwireHeadingProps } from "components/flexComponents/WizardPWARegions/WizardRegionHotwire/typings";
import { SuperscriptSmallCharacters } from "components/shared/SuperscriptSpecialCharacters/SuperscriptSmallCharacters";

export const MobileHeading = ({ title, innerTitle, scrimContainer }: WizardRegionHotwireHeadingProps) => (
  <EGDSHeading tag="h1" size={5}>
    {title &&
      (!scrimContainer ? (
        <EGDSText size={500}>
          <SuperscriptSmallCharacters>{title}</SuperscriptSmallCharacters>
        </EGDSText>
      ) : (
        <EGDSSubheading>
          <SuperscriptSmallCharacters>{title}</SuperscriptSmallCharacters>
        </EGDSSubheading>
      ))}
    {innerTitle &&
      (!scrimContainer ? (
        <EGDSText size={700}>
          <SuperscriptSmallCharacters>{innerTitle}</SuperscriptSmallCharacters>
        </EGDSText>
      ) : (
        <EGDSHeading tag="h1">
          <SuperscriptSmallCharacters>{innerTitle}</SuperscriptSmallCharacters>
        </EGDSHeading>
      ))}
  </EGDSHeading>
);
