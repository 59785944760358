import * as React from "react";

import { EGDSHeading, EGDSText } from "@egds/react-core/text";

import { WizardRegionHotwireHeadingProps } from "components/flexComponents/WizardPWARegions/WizardRegionHotwire/typings";
import { SuperscriptSmallCharacters } from "components/shared/SuperscriptSpecialCharacters/SuperscriptSmallCharacters";

export const DesktopHeading = ({ title, innerTitle }: WizardRegionHotwireHeadingProps) => (
  <EGDSHeading tag="h1" size={3}>
    {title && (
      <EGDSText size={700} weight="regular" theme="inverse">
        <SuperscriptSmallCharacters>{title}</SuperscriptSmallCharacters>
      </EGDSText>
    )}
    {innerTitle && (
      <EGDSText size={900} weight="bold" theme="inverse">
        <SuperscriptSmallCharacters>{innerTitle}</SuperscriptSmallCharacters>
      </EGDSText>
    )}
  </EGDSHeading>
);
