import * as React from "react";

import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSSpacing } from "@egds/react-core/spacing";

import { WizardRegionHotwireTemplateComponentConfig } from "../typings";
import { MobileHeading } from "./MobileHeading";

export interface MobileContentNoImageProps {
  config: WizardRegionHotwireTemplateComponentConfig;
  wizard?: any;
}

export const MobileContentNoImage = ({ config, wizard }: MobileContentNoImageProps) => (
  <EGDSSpacing padding={{ blockstart: "six", inline: "three" }}>
    <EGDSLayoutFlex direction="column">
      <EGDSLayoutFlexItem>
        <EGDSSpacing margin={{ blockend: "three" }}>
          <div>
            <MobileHeading title={config.title} innerTitle={config.innerTitle} />
          </div>
        </EGDSSpacing>
      </EGDSLayoutFlexItem>
      <EGDSLayoutFlexItem>{wizard}</EGDSLayoutFlexItem>
    </EGDSLayoutFlex>
  </EGDSSpacing>
);
