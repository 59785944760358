import * as React from "react";
import { MobXProviderContext, observer } from "mobx-react";

import { EGDSCard, EGDSCardContentSection } from "@egds/react-core/cards";
import { EGDSFigure, EGDSFigureAspectRatioType, EGDSImage } from "@egds/react-core/images";
import { EGDSLayoutPosition } from "@egds/react-core/layout-position";
import { EGDSScrim, EGDSScrimType } from "@egds/react-core/scrim";
import { EGDSSpacing } from "@egds/react-core/spacing";

import { MobileHeading } from "./MobileHeading";
import { optimizeHotwireHeroImageUrl } from "src/components/flexComponents/DestinationHero/WizardHeroUtils";
import { useMultiLOBWizardRegion } from "./../WizardRegionContextProvider/MultiLOBWizardRegionContext";
import { WizardRegionHotwireTemplateComponentConfig } from "../typings";
import { ExtendedTemplateComponent } from "src/stores/ExperienceTemplateStore/typings";
import { RegionChildrenList } from "src/utils/RegionUtils";
import { BlossomComponent } from "src/components/flexFramework/domain/BlossomComponent";

export interface MobileContentWithImageProps {
  config: WizardRegionHotwireTemplateComponentConfig;
  destinationHero?: ExtendedTemplateComponent;
  wizard?: any;
  blossomComponent: BlossomComponent;
}

export const MobileContentWithImage = observer(
  ({ config, destinationHero, wizard, blossomComponent }: MobileContentWithImageProps) => {
    const { wizardState } = React.useContext(MobXProviderContext);

    // Override the mobile background image from the config if there is no destination-hero on the page
    if (config.smallBackgroundImage && !destinationHero) {
      wizardState.globalWizardState.config.heroImageSrc = config.smallBackgroundImage;
    }

    const backgroundImageSrc = optimizeHotwireHeroImageUrl(wizardState.globalWizardState.config.heroImageSrc, true);

    const { isMultiLOB } = useMultiLOBWizardRegion();

    return (
      <>
        <EGDSFigure ratio={EGDSFigureAspectRatioType.R3_2}>
          <EGDSImage src={backgroundImageSrc} alt={`${config.title} ${config.innerTitle}`} />
          <EGDSScrim type={EGDSScrimType.BOTTOM}>
            <EGDSSpacing padding={{ blockend: "four" }}>
              <div className="WizardRegionHotwire--Heading">
                <MobileHeading title={config.title} innerTitle={config.innerTitle} scrimContainer />
              </div>
            </EGDSSpacing>
          </EGDSScrim>
        </EGDSFigure>
        <EGDSLayoutPosition type="relative" position={{ top: "minus-three" }}>
          <EGDSCard>
            {destinationHero && (
              <EGDSCardContentSection className="attribution-text">
                <RegionChildrenList templateComponents={[destinationHero]} blossomComponent={blossomComponent} />
              </EGDSCardContentSection>
            )}
            {isMultiLOB ? wizard : <EGDSCardContentSection>{wizard}</EGDSCardContentSection>}
          </EGDSCard>
        </EGDSLayoutPosition>
      </>
    );
  }
);
