import * as React from "react";
import { MobXProviderContext, observer } from "mobx-react";

import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSSpacing } from "@egds/react-core/spacing";

import { WizardRegionHotwireContentProps } from "../typings";
import { DesktopHeading } from "./DesktopHeading";
import { DesktopHeroAttribution } from "./DesktopHeroAttribution";
import { optimizeHotwireHeroImageUrl } from "src/components/flexComponents/DestinationHero/WizardHeroUtils";
import { useMultiLOBWizardRegion } from "./../WizardRegionContextProvider/MultiLOBWizardRegionContext";

export const DesktopContent = observer(
  ({ lineOfBusiness, config, destinationHero, wizard, blossomComponent }: WizardRegionHotwireContentProps) => {
    const { wizardState } = React.useContext(MobXProviderContext);

    // Override the desktop background image from the config if there is no destination-hero on the page
    if (config.backgroundImage && !destinationHero) {
      wizardState.globalWizardState.config.heroImageSrc = config.backgroundImage;
    }

    const backgroundImageSrc = optimizeHotwireHeroImageUrl(wizardState.globalWizardState.config.heroImageSrc, false);

    const { backgroundColor } = config;
    const backgroundImageGradient = "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(23, 26, 35, 0.5) 100%)";
    const backgroundImage = `${backgroundImageGradient}, url("${backgroundImageSrc}")`;
    const { isMultiLOB } = useMultiLOBWizardRegion();
    const isPackages = lineOfBusiness === "package";

    return (
      <EGDSLayoutFlex
        className={`WizardRegionHotwire WizardRegionHotwire--${isMultiLOB ? "MultiLob" : "SingleLob"}`}
        data-testid="wizard-region-hotwire"
        direction="column"
        data-module={`farefinder-${lineOfBusiness}`}
      >
        <div
          className={`WizardRegionHotwire--Background ${isPackages ? "packages" : ""}`}
          style={{ backgroundColor, backgroundImage }}
        />
        <EGDSSpacing padding="twelve">
          <EGDSLayoutFlex className="WizardRegionHotwire--Content" direction="column" justifyContent="center">
            <EGDSSpacing margin={{ blockend: "three" }}>
              <EGDSLayoutFlexItem>
                <div className="WizardRegionHotwire--Heading SimpleContainer">
                  <DesktopHeading title={config.title} innerTitle={config.innerTitle} />
                </div>
              </EGDSLayoutFlexItem>
            </EGDSSpacing>
            <EGDSLayoutFlexItem>{wizard}</EGDSLayoutFlexItem>
          </EGDSLayoutFlex>
        </EGDSSpacing>
        {destinationHero && (
          <DesktopHeroAttribution destinationHero={destinationHero} blossomComponent={blossomComponent} />
        )}
      </EGDSLayoutFlex>
    );
  }
);
