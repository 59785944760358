import * as React from "react";
import { inject, observer } from "mobx-react";

import { withLocalization } from "@shared-ui/localization-context";
import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { logger } from "bernie-core";

import {
  WizardRegionHotwireContentProps,
  WizardRegionHotwireTemplateComponentConfig,
  WizardRegionHotwireProps,
} from "./typings";
import { DesktopContent } from "./components/DesktopContent";
import { MobileContent } from "./components/MobileContent";
import { getWizardLOBTemplateComponents } from "src/components/shared/StorefrontWizard/StorefrontWizardUtils";
import { MultiLOBWizardRegionContext } from "./WizardRegionContextProvider/MultiLOBWizardRegionContext";
import { LobQueryRouter } from "src/components/shared/StorefrontWizard/LobQueryRouter";
import {
  TabbedWizardCard,
  WizardRegionType,
} from "src/components/shared/StorefrontWizard/TabbedWizardCard/TabbedWizardCard";
import { useHotwireCarOverrides } from "components/flexComponents/WizardCarPWA/utils/hotwireCarOverrides";
import { useHotwireHotelOverrides } from "components/flexComponents/WizardHotelPWAv2/hooks/useHotwireHotelOverrides";
import { useHotwireFlightOverrides } from "components/flexComponents/WizardFlightPWA/utils/hotwireFlightOverrides";
import { useHotwirePackageOverrides } from "components/flexComponents/WizardPackagePWA/utils/hotwirePackageOverrides";
import { ClientWizardStateStore } from "stores/wizard/ClientWizardStateStore";
import { UitkTabsType as EGDSTabsType } from "uitk-react-experimental-button-tabs";
import { ExtendedTemplateComponent } from "src/stores/ExperienceTemplateStore/typings";
import { RegionChildrenList } from "src/utils/RegionUtils";

interface LineOfBusinessParams {
  wizardFormChildren: ExtendedTemplateComponent[];
  wizardState: ClientWizardStateStore;
  isMultiLOB: boolean;
}

/**
 * Get the LOB tracking string from the current wizard state: either the lob (hotel/car/flight/package) or "unknown".
 */
const getLineOfBusiness = ({ wizardFormChildren, wizardState, isMultiLOB }: LineOfBusinessParams) => {
  const wizardKey = (isMultiLOB && wizardState.activeLob) || wizardFormChildren?.[0]?.metadata?.id;

  return wizardKey?.toString()?.replace("wizard-", "")?.split("-")?.[0] || "unknown";
};

const childContainsKey = (child: ExtendedTemplateComponent, keySubstring: string) =>
  child?.metadata?.id?.includes(keySubstring);

/**
 * This region, based on StorefrontWizardRegionCOMET but simplified for a MVP use-case,
 * expects one wizard component to be added. (for now, it does not support multiple LOBs).
 */
export const WizardRegionHotwire = withLocalization(
  logger("WizardRegionHotwire")(
    inject(
      "context",
      "wizardState"
    )(
      observer((props: WizardRegionHotwireProps) => {
        const { templateComponent, context, wizardState, blossomComponent } = props;

        if (!blossomComponent || !templateComponent.children || templateComponent.children.length < 1) {
          return null;
        }

        const config = templateComponent.config as WizardRegionHotwireTemplateComponentConfig;

        useHotwireHotelOverrides(wizardState.hotelWizardState);
        useHotwireCarOverrides(wizardState.carWizardState);
        useHotwireFlightOverrides(wizardState.flightWizardState);
        useHotwirePackageOverrides(wizardState.packageWizardState);

        wizardState.globalWizardState.setIsDesktop(context.deviceInformation);

        const wizardFormChildren = getWizardLOBTemplateComponents(templateComponent.children);
        const isMultiLOB = wizardFormChildren?.length > 1;

        const destinationHero = templateComponent.children.find((child) => childContainsKey(child, "destination-hero"));
        const lineOfBusiness = getLineOfBusiness({ wizardFormChildren, wizardState, isMultiLOB });

        const contentProps: WizardRegionHotwireContentProps = {
          lineOfBusiness,
          config,
          destinationHero,
          wizard: isMultiLOB ? (
            <TabbedWizardCard
              wizardFormChildren={wizardFormChildren}
              wizardState={wizardState}
              wizardRegionType={WizardRegionType.BEX}
              wrappingClassNames="SimpleContainer"
              wizardFormSpacing={{ padding: { blockend: "six" } }}
              tabsType={EGDSTabsType.EQUAL_WIDTH}
              blossomComponent={blossomComponent}
            />
          ) : (
            wizardFormChildren[0] && (
              <RegionChildrenList templateComponents={[wizardFormChildren[0]]} blossomComponent={blossomComponent} />
            )
          ),
          blossomComponent,
        };

        return (
          <MultiLOBWizardRegionContext.Provider value={{ isMultiLOB }}>
            <LobQueryRouter lobsTemplateComponent={wizardFormChildren} />
            <Viewport>
              <ViewSmall>
                <MobileContent {...contentProps} />
              </ViewSmall>
              <ViewMedium>
                <DesktopContent {...contentProps} />
              </ViewMedium>
            </Viewport>
          </MultiLOBWizardRegionContext.Provider>
        );
      })
    )
  )
);

export default WizardRegionHotwire;
