import * as React from "react";

import { ViewExtraLarge, ViewMedium, Viewport } from "@shared-ui/viewport-context";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { ExtendedTemplateComponent } from "src/stores/ExperienceTemplateStore/typings";
import { RegionChildrenList } from "src/utils/RegionUtils";
import { BlossomComponent } from "src/components/flexFramework/domain/BlossomComponent";

export interface DesktopHeroAttributionProps {
  destinationHero: ExtendedTemplateComponent;
  blossomComponent: BlossomComponent;
}

export const DesktopHeroAttribution = ({ destinationHero, blossomComponent }: DesktopHeroAttributionProps) => (
  <Viewport>
    <ViewMedium>
      <EGDSSpacing padding={{ blockend: "six", inlinestart: "three" }}>
        <div className="destination-hero">
          <RegionChildrenList templateComponents={[destinationHero]} blossomComponent={blossomComponent} />
        </div>
      </EGDSSpacing>
    </ViewMedium>
    <ViewExtraLarge>
      <EGDSSpacing padding={{ blockend: "six" }}>
        <div className="destination-hero">
          <RegionChildrenList templateComponents={[destinationHero]} blossomComponent={blossomComponent} />
        </div>
      </EGDSSpacing>
    </ViewExtraLarge>
  </Viewport>
);
