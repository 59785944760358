import * as React from "react";

import { WizardRegionHotwireContentProps } from "../typings";
import { MobileContentWithImage } from "./MobileContentWithImage";
import { MobileContentNoImage } from "./MobileContentNoImage";

export const MobileContent = ({
  lineOfBusiness,
  config,
  destinationHero,
  wizard,
  blossomComponent,
}: WizardRegionHotwireContentProps) => {
  const hasBackgroundImage = !!(config.smallBackgroundImage || destinationHero);

  return (
    <div
      className="WizardRegionHotwire"
      data-testid="wizard-region-hotwire"
      data-module={`farefinder-${lineOfBusiness}`}
    >
      {hasBackgroundImage ? (
        <MobileContentWithImage
          config={config}
          destinationHero={destinationHero}
          wizard={wizard}
          blossomComponent={blossomComponent}
        />
      ) : (
        <MobileContentNoImage config={config} wizard={wizard} />
      )}
    </div>
  );
};
